import React, { Component } from 'react'
import cx from 'classnames'

// modules
import Router from '@modules/router'
import {
  STATE_DATA_REQUEST_STATUS,
  STATE_DATA_REQUEST_ERROR,
  STATE_DATA
} from '@compositions/Communities/components/Index/modules/constants'
import { fetchCommunities } from '@compositions/Communities/components/Index/modules/helpers'
import Cloudinary from '@modules/cloudinary'

// components
import ListWidget, { Item } from '@components/ListWidget'
import Avatar from '@components/Avatar'
import Icon from '@components/Icon'
import { string, func, bool } from 'prop-types'

// styles
import styles from './_.module.scss'

class CommunitiesWidget extends Component {
  constructor(props) {
    super(props)

    this.state = {
      [STATE_DATA]: [],
      [STATE_DATA_REQUEST_STATUS]: true,
      [STATE_DATA_REQUEST_ERROR]: null
    }
  }
  componentDidMount() {
    const { markAsDone } = this.props
    fetchCommunities(
      { params: { itemsPerPage: 4, sortOrder: 1, sortColumn: 0 } },
      this,
      () => markAsDone(true)
    )
  }

  render() {
    const {
      [STATE_DATA]: data,
      [STATE_DATA_REQUEST_ERROR]: dataRequestError,
      [STATE_DATA_REQUEST_STATUS]: dataRequestStatus
    } = this.state
    const { className, display } = this.props

    dataRequestError && console.warn(dataRequestError)

    const communities = data.results || []
    const meta = data.meta || {}

    const totalCommunities = (meta && meta.totalItems) || 0

    if (!display) return null

    return dataRequestStatus ? (
      <div id="dashboard-communities-widget-loading">Loading...</div>
    ) : dataRequestError ? (
      <div id="dashboard-communities-widget-error">An error occurred while loading data...</div>
    ) : (
      <ListWidget
        id="dashboard-communities-widget-loaded"
        className={className}
        header={
          <Item
            id="dashboard-communities-widget-mainlink"
            className="pa-4"
            palette="blue"
            onClick={() => Router.go('/communities')}
            icon={
              <Icon
                name="house-home"
                size={20}
                style={{
                  marginTop: '2px',
                  marginBottom: '-2px'
                }}
              />
            }
            total={totalCommunities > 1 ? totalCommunities : null}
            role="link"
          >
            Communities
          </Item>
        }
      >
        {communities.map((item, key) => (
          <Item
            key={key}
            id={`dashboard-communities-widget-linknum-${key + 1}`}
            onClick={() => Router.go(`/communities/${item.id}`)}
            contentClassName="ov-h"
            icon={
              item.picture ? (
                <div
                  className={styles.pictureImage}
                  style={{
                    backgroundImage: `url(${Cloudinary.tranformImage(item.picture, 'w_180')})`
                  }}
                  title={`Thumbnail photo of ${item.name} property`}
                />
              ) : (
                <Avatar.Contained
                  className={styles.pictureImage}
                  iconName="gallery"
                  size="sm"
                />
              )
            }
            role="link"
          >
            <div
              className={cx('di-f', 'fldi-c', 'ov-h', styles.communitiesList)}
            >
              <div
                className="ov-h whsp-nw teov-e co-black fowe-b"
                title={item.name}
              >
                {item.name}
              </div>
              <div
                className="ov-h whsp-nw teov-e co-gray-4"
                title={item.address}
              >
                {item.address}
              </div>
            </div>
          </Item>
        ))}
      </ListWidget>
    )
  }
}

CommunitiesWidget.propTypes = {
  className: string,
  display: bool,
  markAsDone: func
}

CommunitiesWidget.defaultProps = {
  className: null,
  display: false,
  markAsDone: () => null
}

export default CommunitiesWidget
