import React, { Component } from 'react'

// components
import MediaObject, { Sidebar, Content } from '@components/MediaObject'
import Loader from '@components/Loader'
import LeadsWidget from './components/LeadsWidget'
import CommunitiesWidget from './components/CommunitiesWidget'
import NewLeads from './components/NewLeads'
import ScheduledTours from './components/ScheduledTours'

// styles
import styles from './_.module.scss'

const STATE_COMMUNITY_WIDGET_READY = 'communityWidgetReady'
const STATE_LEADS_WIDGET_READY = 'leadsWidgetReady'
const STATE_NEW_LEADS_READY = 'newLeadsReady'
const STATE_SCHEDULED_TOURS_READY = 'scheduledToursReady'
class Dashboard extends Component {
  state = {
    [STATE_COMMUNITY_WIDGET_READY]: false,
    [STATE_LEADS_WIDGET_READY]: false,
    [STATE_NEW_LEADS_READY]: false,
    [STATE_SCHEDULED_TOURS_READY]: false
  }
  render() {
    const {
      [STATE_COMMUNITY_WIDGET_READY]: communityWidgetReady,
      [STATE_LEADS_WIDGET_READY]: leadsWidgetReady,
      [STATE_NEW_LEADS_READY]: newLeadsReady,
      [STATE_SCHEDULED_TOURS_READY]: scheduledToursReady
    } = this.state
    const componentsReady =
      communityWidgetReady &&
      leadsWidgetReady &&
      newLeadsReady &&
      scheduledToursReady

    return (
      <>
        <MediaObject alignCenter={false}>
          <Sidebar className="di-b di-n-tpo par-4 mar-4 fldi-c" width="34%">
            {!componentsReady && (
              <>
                <Loader.ListWidget />
                <Loader.ListWidget />
              </>
            )}
            <LeadsWidget
              markAsDone={() =>
                this.setState({ [STATE_LEADS_WIDGET_READY]: true })
              }
              display={componentsReady}
            />
            <CommunitiesWidget
              className="mat-4"
              markAsDone={() =>
                this.setState({ [STATE_COMMUNITY_WIDGET_READY]: true })
              }
              display={componentsReady}
            />
          </Sidebar>
          <Content className={styles.dashboardPage}>
            {!componentsReady && (
              <>
                <Loader.GridWidget />
                <Loader.GridWidget />
              </>
            )}
            <NewLeads
              className="mab-4"
              markAsDone={() =>
                this.setState({ [STATE_NEW_LEADS_READY]: true })
              }
              display={componentsReady}
            />
            <ScheduledTours
              markAsDone={() =>
                this.setState({ [STATE_SCHEDULED_TOURS_READY]: true })
              }
              display={componentsReady}
            />
          </Content>
        </MediaObject>
      </>
    )
  }
}

export default Dashboard
