import React, { Component } from 'react'
import { func, bool } from 'prop-types'

// modules
import { Collection } from '@modules/rest-api'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'
import Router from '@modules/router'
import datejs from '@modules/datejs'
import { dateToUTC } from '@modules/time'

// components
import ListWidget, { Item } from '@components/ListWidget'
import Icon from '@components/Icon'

const STATUS_CODE_ALL_LEADS = 0
const STATE_REQUEST_STATUS = 'leadsStatus'
const STATE_REQUEST_RESPONSE = 'leadsResponse'
const STATE_REQUEST_ERROR = 'leadsError'

const fetchBreakdownOfLeadCounts = (ctx, done = () => null) => {
  const config = {
    params: {
      startDate: dateToUTC(datejs().subtract(12, 'months')),
      endDate: dateToUTC()
    },
    apiVersion: API_VERSION
  }

  const leads = new Collection(REST_API_ENDPOINTS.breakdownStatusCounts, 1)
  leads.readSigned(config, (err, res) => {
    ctx.setState({
      [STATE_REQUEST_ERROR]: err,
      [STATE_REQUEST_STATUS]: false,
      [STATE_REQUEST_RESPONSE]: (res && res.data) || []
    })
    done()
  })
}
class LeadsWidget extends Component {
  state = {
    [STATE_REQUEST_STATUS]: true,
    [STATE_REQUEST_ERROR]: null,
    [STATE_REQUEST_RESPONSE]: []
  }
  componentDidMount() {
    const { markAsDone } = this.props
    fetchBreakdownOfLeadCounts(this, markAsDone)
  }
  render() {
    const {
      [STATE_REQUEST_STATUS]: requestStatus,
      [STATE_REQUEST_ERROR]: requestError,
      [STATE_REQUEST_RESPONSE]: requestResponse
    } = this.state
    const { display } = this.props

    requestError && console.warn(requestError)
    const breakdownStatuses = (requestResponse && requestResponse.results) || []
    let totalLeads = null
    if (breakdownStatuses.length > 0) {
      totalLeads = breakdownStatuses.find(
        item => item.code === STATUS_CODE_ALL_LEADS
      )
    }

    if (!display) return null

    return requestStatus ? (
      <div id="dashboard-leads-widget-loading">Loading...</div>
    ) : requestError ? (
      <div id="dashboard-leads-widget-error">An error occurred while loading data...</div>
    ) : (
      <ListWidget
        id="dashboard-leads-widget-loaded"
        header={
          <Item
            id="dashboard-leads-widget-mainlink"
            className="pa-4"
            palette="blue"
            onClick={() => Router.go('/leads')}
            icon={
              <Icon
                name="checklist"
                size={20}
                style={{
                  marginTop: '2px',
                  marginBottom: '-2px'
                }}
              />
            }
            total={totalLeads && totalLeads.count}
            role="link"
          >
            Leads
          </Item>
        }
      >
        {breakdownStatuses.map(
          (item, key) =>
            item.code !== STATUS_CODE_ALL_LEADS && (
              <Item
                id={`dashboard-leads-widget-linknum-${key}`}
                key={key}
                onClick={() => Router.go(`/leads?status=${item.code}`)}
                total={item.count}
                role="link"
              >
                {item.name}
              </Item>
            )
        )}
      </ListWidget>
    )
  }
}

LeadsWidget.propTypes = {
  display: bool,
  markAsDone: func
}

LeadsWidget.defaultProps = {
  display: false,
  markAsDone: () => null
}

export default LeadsWidget
