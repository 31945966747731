import React from 'react'

// compositions
import Layout from '@compositions/Layout'
import Dashboard from '@compositions/Dashboard'

const breadcrumbs = [{ key: 'dashboard', text: 'Dashboard', url: '/dashboard' }]

const DashboardPage = () => (
  <Layout breadcrumbs={breadcrumbs} flagName="dashboard">
    <Dashboard />
  </Layout>
)

export default DashboardPage
