import React, { Component } from 'react'
import cx from 'classnames'
import { string, bool, func } from 'prop-types'

// modules
import datejs from '@modules/datejs'
import { Collection } from '@modules/rest-api'
import { dateToUTC } from '@modules/time'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'
import { ITEMS_PER_PAGE, SORT_ORDER_DESC } from '../../modules/constants'

// components
import Label from '@components/Label'

// styles
import styles from './_.module.scss'

const STATE_REQUEST_STATUS = 'newLeadsStatus'
const STATE_REQUEST_RESPONSE = 'newLeadsResponse'
const STATE_REQUEST_ERROR = 'newLeadsError'
const SORT_COLUMN_CODE_REFERRED_DATE = 0

const fetchNewLeads = (ctx, done = () => null) => {
  const config = {
    params: {
      itemsPerPage: ITEMS_PER_PAGE,
      startDate: dateToUTC(datejs().subtract(30, 'days')),
      endDate: dateToUTC(datejs()),
      sortColumn: SORT_COLUMN_CODE_REFERRED_DATE,
      sortOrder: SORT_ORDER_DESC,
      page: 1
    },
    apiVersion: API_VERSION
  }

  const leads = new Collection(REST_API_ENDPOINTS.referrals, 1)
  leads.readSigned(config, (err, res) => {
    ctx.setState({
      [STATE_REQUEST_ERROR]: err,
      [STATE_REQUEST_STATUS]: false,
      [STATE_REQUEST_RESPONSE]: (res && res.data) || []
    })
    done()
  })
}

class NewLeads extends Component {
  state = {
    [STATE_REQUEST_STATUS]: true,
    [STATE_REQUEST_ERROR]: null,
    [STATE_REQUEST_RESPONSE]: []
  }
  componentDidMount() {
    const { markAsDone } = this.props
    fetchNewLeads(this, markAsDone)
  }
  render() {
    const { className, display } = this.props
    const {
      [STATE_REQUEST_STATUS]: requestStatus,
      [STATE_REQUEST_ERROR]: requestError,
      [STATE_REQUEST_RESPONSE]: requestResponse
    } = this.state
    const { results, meta } = requestResponse || {}
    const { totalItems } = meta || {}

    if (!display) return null

    return requestStatus ? (
      <div id="dashboard-newleads-loading">Loading...</div>
    ) : requestError ? (
      <div id="dashboard-newleads-error">There was an error loading the content</div>
    ) : (
      <div
        id="dashboard-newleads-loaded"
        className={cx(
          'pa-4',
          'bowi-1',
          'boco-gray-2',
          'bost-s',
          'bora-1',
          'di-f',
          'fldi-c',
          styles.container,
          className
        )}
      >
        <h3 className={cx('co-black', 'pab-4', styles.title)}>
          New Leads in Last 30 Days
        </h3>
        <div className="di-f flwr-w">
          {results &&
            (results.length > 0 ? (
              results.map((lead, key) => {
                const {
                  referredDate,
                  lead: leadObj,
                  community,
                  referralStatus,
                  scheduledTourDate
                } = lead
                const { advisor, resident } = leadObj || {}
                const leadName =
                  (resident && `${resident.firstName} ${resident.lastName}`) ||
                  ''
                const communityName = (community && community.name) || ''
                const residentName =
                  (resident && `${resident.firstName} ${resident.lastName}`) ||
                  ''
                const advisorName =
                  (advisor && `${advisor.firstName} ${advisor.lastName}`) || ''
                return (
                  <div
                    key={key}
                    id={`dashboard-newleads-lead-${key + 1}`}
                    className={cx(
                      'boco-gray-2',
                      'bowi-1',
                      'bost-s',
                      'bora-1',
                      'pax-2',
                      'pay-4',
                      'mab-2',
                      'di-f',
                      'fldi-c',
                      'co-gray-4',
                      'po-r',
                      'fosi-2',
                      styles.leadBox
                    )}
                  >
                    <div className="fosi-1">
                      {datejs(referredDate).format('MM/DD/YYYY')}
                    </div>
                    <div className="co-black fowe-b">{leadName}</div>
                    <div>{communityName}</div>
                    <div className="di-f pat-2">
                      <div
                        className={cx(
                          'di-f',
                          'fldi-c',
                          styles.residentAdvisorBox
                        )}
                      >
                        <div className="fosi-1 co-gray-3">Resident</div>
                        <div>{residentName}</div>
                      </div>
                      <div
                        className={cx(
                          'di-f',
                          'fldi-c',
                          styles.residentAdvisorBox
                        )}
                      >
                        <div className="fosi-1 co-gray-3">Advisor</div>
                        <div>{advisorName}</div>
                      </div>
                    </div>
                    {referralStatus === 'Tour Scheduled' && (
                      <div className={cx('po-a', styles.scheduledDate)}>
                        <Label
                          className={cx(
                            'co-orange-3',
                            'fosi-2',
                            styles.dateLabel
                          )}
                        >
                          {datejs(scheduledTourDate).format('hh:mm A, MMM D')}
                        </Label>
                      </div>
                    )}
                  </div>
                )
              })
            ) : (
              <div id="dashboard-newleads-noresults">You’ve received no new leads in the last 30 days.</div>
            ))}
        </div>
        {totalItems > 0 && (
          <div id="dashboard-newleads-recentlink" className={styles.title}>
            <a title="See all recent leads" href="/leads/">
              See all recent leads ({totalItems})
            </a>
          </div>
        )}
      </div>
    )
  }
}

NewLeads.propTypes = {
  className: string,
  display: bool,
  markAsDone: func
}
NewLeads.defaultProps = {
  className: null,
  display: false,
  markAsDone: () => null
}

export default NewLeads
