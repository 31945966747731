import React, { Component } from 'react'
import cx from 'classnames'
import { func, bool } from 'prop-types'

// modules
import datejs from '@modules/datejs'
import { Collection } from '@modules/rest-api'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'
import { ITEMS_PER_PAGE, SORT_ORDER_ASC } from '../../modules/constants'
import { dateToUTC } from '@modules/time'

// components
import Label from '@components/Label'

// styles
import styles from './_.module.scss'

const STATE_REQUEST_STATUS = 'scheduledToursStatus'
const STATE_REQUEST_RESPONSE = 'scheduledToursResponse'
const STATE_REQUEST_ERROR = 'scheduledToursError'

const STATUS_CODE_TOUR_SCHEDULED = 2
const SORT_COLUMN_CODE_SCHEDULED_TOUR = 7

const fetchScheduledTours = (ctx, done = () => null) => {
  const config = {
    params: {
      statusFlags: STATUS_CODE_TOUR_SCHEDULED,
      itemsPerPage: ITEMS_PER_PAGE,
      startDate: dateToUTC(datejs().subtract(12, 'months')),
      endDate: dateToUTC(),
      scheduledTourStartDate: dateToUTC(),
      scheduledTourEndDate: dateToUTC(datejs().add(7, 'days')),
      sortColumn: SORT_COLUMN_CODE_SCHEDULED_TOUR,
      sortOrder: SORT_ORDER_ASC,
      page: 1
    },
    apiVersion: API_VERSION
  }

  const leads = new Collection(REST_API_ENDPOINTS.referrals, 1)
  leads.readSigned(config, (err, res) => {
    ctx.setState({
      [STATE_REQUEST_ERROR]: err,
      [STATE_REQUEST_STATUS]: false,
      [STATE_REQUEST_RESPONSE]: (res && res.data) || []
    })
    done()
  })
}
class ScheduledTours extends Component {
  state = {
    [STATE_REQUEST_STATUS]: true,
    [STATE_REQUEST_ERROR]: null,
    [STATE_REQUEST_RESPONSE]: []
  }
  componentDidMount() {
    const { markAsDone } = this.props
    fetchScheduledTours(this, markAsDone)
  }
  render() {
    const { display } = this.props
    const {
      [STATE_REQUEST_STATUS]: requestStatus,
      [STATE_REQUEST_ERROR]: requestError,
      [STATE_REQUEST_RESPONSE]: requestResponse
    } = this.state
    const { results, meta } = requestResponse || {}
    const { totalItems } = meta || {}
    if (!display) return null
    return requestStatus ? (
      <div id="dashboard-scheduledtours-loading">Loading...</div>
    ) : requestError ? (
      <div id="dashboard-scheduledtours-error">There was an error loading the content</div>
    ) : (
      <div
        id="dashboard-scheduledtours-loaded"
        className={cx(
          'pa-4',
          'bowi-1',
          'boco-gray-2',
          'bost-s',
          'bora-1',
          'di-f',
          'fldi-c',
          styles.container
        )}
      >
        <h3 className={cx('co-black', 'pab-4', styles.title)}>
          Scheduled Tours in Next 7 Days
        </h3>
        <div className="di-f flwr-w">
          {results &&
            (results.length > 0 ? (
              results.map((lead, key) => {
                const {
                  referredDate,
                  lead: leadObj,
                  community,
                  scheduledTourDate
                } = lead
                const { advisor, resident, contact } = leadObj || {}
                const leadName =
                  (contact && `${contact.firstName} ${contact.lastName}`) ||
                  ''
                const communityName = (community && community.name) || ''
                const residentName =
                  (resident && `${resident.firstName} ${resident.lastName}`) ||
                  ''
                const advisorName =
                  (advisor && `${advisor.firstName} ${advisor.lastName}`) || ''
                return (
                  <div
                    key={key}
                    id={`dashboard-scheduledtours-lead-${key + 1}`}
                    className={cx(
                      'boco-gray-2',
                      'bowi-1',
                      'bost-s',
                      'bora-1',
                      'pax-2',
                      'pay-4',
                      'mab-2',
                      'di-f',
                      'fldi-c',
                      'co-gray-4',
                      'po-r',
                      'fosi-2',
                      styles.leadBox
                    )}
                  >
                    <div className="fosi-1">
                      {datejs(referredDate).format('MM/DD/YYYY')}
                    </div>
                    <div className="co-black fowe-b">{leadName}</div>
                    <div>{communityName}</div>
                    <div className="di-f pat-2">
                      <div
                        className={cx(
                          'di-f',
                          'fldi-c',
                          styles.residentAdvisorBox
                        )}
                      >
                        <div className="fosi-1 co-gray-3">Resident</div>
                        <div>{residentName}</div>
                      </div>
                      <div
                        className={cx(
                          'di-f',
                          'fldi-c',
                          styles.residentAdvisorBox
                        )}
                      >
                        <div className="fosi-1 co-gray-3">Advisor</div>
                        <div>{advisorName}</div>
                      </div>
                    </div>
                    <div className={cx('po-a', styles.scheduledDate)}>
                      <Label
                        className={cx(
                          'co-orange-3',
                          'fosi-2',
                          styles.dateLabel
                        )}
                      >
                        {datejs(scheduledTourDate).format('hh:mm A, MMM D')}
                      </Label>
                    </div>
                  </div>
                )
              })
            ) : (
              <div id="dashboard-scheduledtours-noresults">You have no scheduled tours in the next 7 days.</div>
            ))}
        </div>
        {totalItems > 0 && (
          <div className={styles.title}>
            <a
              title="See all scheduled tours"
              href={`/leads/?status=${STATUS_CODE_TOUR_SCHEDULED}`}
            >
              See all scheduled tours ({totalItems})
            </a>
          </div>
        )}
      </div>
    )
  }
}
ScheduledTours.propTypes = {
  display: bool,
  markAsDone: func
}

ScheduledTours.defaultProps = {
  display: false,
  markAsDone: () => null
}

export default ScheduledTours
